@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.max-width {
  max-width: 672px;
}

.title {
  text-transform: uppercase;
}
form button {
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.form-category {
  font-size: 18px;
  font-weight: 900;
}

.info {
  font-size: 14px;
}
