@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.link-button {
  display: block;
  text-align: center;
  padding: 0 !important;
  margin-bottom: 48px;
  .icon {
    width: 12px;
    height: 12px;
  }
}
