@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.radio-input label,
.checkbox-input label {
  color: #222428;
  font-size: 14px;
}

h2.form-category {
  font-size: 20px;
  font-weight: 500;
  color: #222428;

  &.small {
    font-size: 16px;
  }
}

/* Showcase responsive layout classes */
.responsive-3-columns {
  column-count: 3;
  column-gap: 20px;

  @media (max-width: 600px) {
    column-count: 1;
    column-gap: 0px;
  }
}

.responsive-margin-right {
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0;
  }
}

.responsive-flex {
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.flex-desktop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
