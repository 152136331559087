@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
.info-title {
  font-size: 14px;
  color: #3f3f3f;
  margin-bottom: 0;
}

.info-content {
  font-size: 14px;
  font-weight: 900;
  color: #3f3f3f;
  margin-bottom: 24px;
}

.info-wrapper {
  display: flex;
  flex-wrap: wrap;

  > div {
    width: calc(50% - 10px);
    padding: 0 16px 0 0;
  }
}

@media (min-width: 768px) {
  .show-mobile {
    display: none;
  }
}
