@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
h1 {
  letter-spacing: -0.4px;

  &.title {
    font-family: "Gene Condensed";
    font-size: 56px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.link {
  padding-right: 20px;
  display: inline-block;
  position: relative;

  &:after {
    position: absolute;
    top: 7px;
    right: 0px;
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%230077D9;%7D%0A%3C/style%3E%3Cpolyline class='st0' points='13.7,9.9 7.3,3.5 6.3,4.5 11.8,10 6.3,15.5 7.3,16.5 13.7,9.9 '/%3E%3C/svg%3E%0A");
  }
}

.submission-layout {
  position: relative;

  .title {
    max-width: 776px;
    margin-bottom: 58px;
  }

  .nav-top {
    position: absolute;
    top: 4px;
    right: 0;
    width: 68px;
    height: 24px;
  }

  .col_title {
    font-size: 16px;
    font-weight: 700;
    color: #25282a;
  }

  .columns {
    display: flex;
    width: 100%;
    margin-bottom: 56px;
    height: auto;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }

    .col_title {
      font-size: 16px;
      font-weight: 700;
      color: #25282a;
    }

    .column {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        padding-right: 20px;
        margin-bottom: 0;

        &.column_33 {
          width: 33.33%;
        }

        &.column_66 {
          width: 66.66%;
        }
      }
    }
  }
}

.comment-section {
  .comment {
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 25px;

    p {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 15px;
    }

    .meta {
      font-weight: 900;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.showcase-actions {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e2e2;

  .action-item {
    font-size: 14px;
    font-weight: 900;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    text-align: center;

    &.share {
      a {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
      }
    }

    &:not(:last-of-type) {
      margin-right: 20px;
    }

    .icon {
      width: 20px;
      margin: 0;
    }
  }

  @media (max-width: 900px) {
    justify-content: center;
  }
}

.link-button {
  width: auto;
}

@media (min-width: 768px) {
  .showcase-actions {
    .action-item {
      flex-direction: row;

      .icon {
        margin-right: 5px;
      }

      &.share {
        a {
          flex-direction: row;
        }
      }
    }
  }
}
