@use "sass:math"; @import "@client/scss/__config.scss"; @import "@theme/scss/_config.scss"; @import "@core/plugins/scss/_mixins.scss";
html.main {
  .video {
    .modal__contents {
      border-radius: 16px;
      video {
        border-radius: 16px;
      }
    }
  }
  button.close {
    .icon {
      width: 24px;
      height: 24px;
      vertical-align: top;
    }
    &:hover {
      background: #16a0e1;
    }
  }
}
